import React from 'react';
import { ICollection } from '../../shop/shop.interface';
import styled from '@emotion/styled';

const StyledDescriptionContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const HtmlContainer = styled.div`
  h1,
  h2,
  h3 {
    font-weight: bold;
  }
  font-size: 14;
  color: white;
`


interface Props {
    collection: ICollection;
}

const CollectionDescription: React.FC<Props> = ({ collection }) => {
    const link = collection.metafields.find((m: any) => m.key === 'link')?.value;

    return (
       <div className="bg-white">
       <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-8 lg:max-w-[1400px]">
         <div className="relative overflow-hidden rounded-lg lg:h-[550px]">
           <div className="absolute inset-0">
             <img
               src={collection?.image?.originalSrc}
               alt="{collection.title}"
               className="h-full w-full object-cover object-center"
             />
           </div>
           <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
           <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
           <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
             <div>
               <h2 className="text-xl font-bold text-white">{collection.title}</h2>
               <StyledDescriptionContainer>
            <HtmlContainer dangerouslySetInnerHTML={{ __html: collection.descriptionHtml }} />
            </StyledDescriptionContainer>
             </div>
             <a
               href={link}
               className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
             >
               Gå til Kollektionen
             </a>
           </div>
         </div>
       </div>
     </div>
    );
};

export default CollectionDescription;
